<template>
  <v-container>
    <v-snackbar color="red" v-model="activatedSnackbar" :timeout="timeout">
      {{ this.$t("roomControl.dndAct") }}
      <br />
      {{ this.$t("roomControl.dndActDesc") }}
      <v-btn text @click="activatedSnackbar = false">
        {{ $t("general.close") }}
      </v-btn>
    </v-snackbar>
    <v-snackbar
      color="success"
      v-model="deactivatedSnackbar"
      :timeout="timeout"
    >
      {{ this.$t("roomControl.dndDeact") }}
      <v-btn text @click="deactivatedSnackbar = false">
        {{ $t("general.close") }}
      </v-btn>
    </v-snackbar>
    <v-tabs grow>
      <v-tab>{{ this.$t("roomControl.bedroom") }}</v-tab>
      <v-tab data-v-step="roomctrl_8">{{
        this.$t("roomControl.livingroom")
      }}</v-tab>
      <v-tab-item>
        <v-card outlined>
          <v-container>
            <v-row dense>
              <v-col
                cols="6"
                align-self="center"
                class="font-weight-medium display-4 text-center"
                >{{
                  (isFahrenheit ? bedroomTemp : bedroomTemp) + "&deg;"
                }}</v-col
              >
              <v-col cols="6" align-self="center" class="text-center">
                <template>
                  <v-icon color="blue" v-if="bedroomTemp < 19" size="100"
                    >mdi-thermometer-low</v-icon
                  >
                  <v-icon
                    v-else-if="bedroomTemp > 18 && bedroomTemp < 25"
                    size="100"
                    >mdi-thermometer</v-icon
                  >
                  <v-icon color="red" v-else size="100"
                    >mdi-thermometer-high</v-icon
                  >
                </template>
              </v-col>
            </v-row>
            <v-row data-v-step="roomctrl_0">
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  icon
                  @click="bedroomTemp--"
                  :disabled="bedroomTemp < 17"
                  x-small
                  fab
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-slider
                  min="16"
                  max="31"
                  v-model="bedroomTemp"
                  class="content"
                ></v-slider>
              </v-col>
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  icon
                  @click="bedroomTemp++"
                  :disabled="bedroomTemp > 30"
                  x-small
                  fab
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row data-v-step="roomctrl_1">
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  color="blue"
                  icon
                  :disabled="bedroomFanspeed < 1"
                  @click="bedroomFanCtrl"
                >
                  <v-icon v-if="bedroomFanspeed > 0">mdi-fan-off</v-icon>
                  <v-icon v-else>mdi-fan</v-icon>
                </v-btn>
              </v-col>
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  icon
                  @click="bedroomFanspeed--"
                  :disabled="bedroomFanspeed < 1"
                  x-small
                  fab
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col class="content grow">
                <v-slider
                  ticks
                  min="0"
                  max="4"
                  v-model="bedroomFanspeed"
                ></v-slider>
              </v-col>
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  icon
                  @click="bedroomFanspeed++"
                  :disabled="bedroomFanspeed > 3"
                  x-small
                  fab
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row data-v-step="roomctrl_2">
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  color="yellow"
                  icon
                  :disabled="bedroomLight < 1"
                  @click="bedroomLightCtrl"
                >
                  <v-icon v-if="bedroomLight > 0">mdi-lightbulb</v-icon>
                  <v-icon v-else>mdi-lightbulb-outline</v-icon>
                </v-btn>
              </v-col>
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  icon
                  :disabled="bedroomLight < 1"
                  @click="bedroomLight--"
                  x-small
                  fab
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col class="content grow">
                <v-slider
                  ticks
                  min="0"
                  max="4"
                  v-model="bedroomLight"
                ></v-slider>
              </v-col>
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  icon
                  :disabled="bedroomLight > 3"
                  @click="bedroomLight++"
                  x-small
                  fab
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card outlined>
          <v-container>
            <v-row dense>
              <v-col
                cols="6"
                align-self="center"
                class="font-weight-medium display-4 text-center"
              >
                {{ (isFahrenheit ? livingroomTemp : livingroomTemp) + "&deg;" }}
              </v-col>
              <v-col cols="6" align-self="center" class="text-center">
                <v-icon color="blue" v-if="livingroomTemp < 19" size="100"
                  >mdi-thermometer-low</v-icon
                >
                <v-icon
                  v-else-if="livingroomTemp > 18 && livingroomTemp < 25"
                  size="100"
                  >mdi-thermometer</v-icon
                >
                <v-icon color="red" v-else size="100"
                  >mdi-thermometer-high</v-icon
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  icon
                  @click="livingroomTemp--"
                  :disabled="livingroomTemp < 17"
                  x-small
                  fab
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-slider
                  min="16"
                  max="31"
                  v-model="livingroomTemp"
                  class="content"
                ></v-slider>
              </v-col>
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  icon
                  @click="livingroomTemp++"
                  :disabled="livingroomTemp > 30"
                  x-small
                  fab
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  color="blue"
                  icon
                  :disabled="livingroomFanspeed < 1"
                  @click="livingroomFanCtrl"
                >
                  <v-icon v-if="livingroomFanspeed > 0">mdi-fan-off</v-icon>
                  <v-icon v-else>mdi-fan</v-icon>
                </v-btn>
              </v-col>
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  icon
                  @click="livingroomFanspeed--"
                  :disabled="livingroomFanspeed < 1"
                  x-small
                  fab
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col class="content grow">
                <v-slider
                  ticks
                  min="0"
                  max="4"
                  v-model="livingroomFanspeed"
                ></v-slider>
              </v-col>
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  icon
                  @click="livingroomFanspeed++"
                  :disabled="livingroomFanspeed > 3"
                  x-small
                  fab
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  color="yellow"
                  icon
                  :disabled="livingroomLight < 1"
                  @click="livingroomLightCtrl"
                >
                  <v-icon v-if="livingroomLight > 0">mdi-lightbulb</v-icon>
                  <v-icon v-else>mdi-lightbulb-outline</v-icon>
                </v-btn>
              </v-col>
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  icon
                  :disabled="livingroomLight < 1"
                  @click="livingroomLight--"
                  x-small
                  fab
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col class="content grow">
                <v-slider
                  ticks
                  min="0"
                  max="4"
                  v-model="livingroomLight"
                ></v-slider>
              </v-col>
              <v-col align-self="center" class="text-center shrink">
                <v-btn
                  icon
                  :disabled="livingroomLight > 3"
                  @click="livingroomLight++"
                  x-small
                  fab
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <v-divider vertical></v-divider>

    <!-- Do Not Disturb -->
    <v-card outlined data-v-step="roomctrl_3">
      <v-card-title class="display-1">{{
        this.$t("roomControl.dnd")
      }}</v-card-title>
      <v-card-text>{{ this.$t("roomControl.dndDesc") }}</v-card-text>
      <v-card-actions>
        <v-btn
          :loading="this.loadingDnd"
          v-if="!dnd"
          @click="setDnd"
          block
          dark
          color="green"
          >{{ this.$t("roomControl.dndOn") }}</v-btn
        >
        <v-btn
          :loading="this.loadingDnd"
          v-else
          @click="setDnd"
          block
          dark
          color="red"
          >{{ this.$t("roomControl.dndOff") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- Do Not Disturb Ende -->
    <v-divider vertical></v-divider>
    <!-- Begin Cancel CleanUp -->
    <v-card class="mb-5" outlined>
      <v-card-title style="word-break: break-word" primary-title>{{
        this.$t("roomservice.cancelHeader")
      }}</v-card-title>
      <v-card-text>
        <div style="white-space: pre-wrap">
          {{ this.$t("roomservice.cancelDescription") }}
        </div>
        <v-btn
          class="mt-3"
          block
          v-if="!$store.state.roomservice.canceled"
          color="red"
          :loading="
            loading || $store.state.roomservice.messageStatus === 'fetching'
          "
          dark
          @click="cancelCleanup()"
          data-v-step="roomctrl_4"
          >{{ $t("roomservice.confirm") }}</v-btn
        >
        <v-btn text class="mt-3" v-else color="green" dark>
          <v-icon class="mr-2">mdi-check</v-icon>
          {{ $t("roomservice.confirmed") }}
        </v-btn>
      </v-card-text>
    </v-card>
    <!-- End Cancel Cleanup -->
    <v-card class="mb-5" outlined data-v-step="roomctrl_5">
      <v-row>
        <v-col cols="9" sm="10" md="11">
          <v-card-title style="word-break: break-word">
            {{ $t("roomservice.scheduleTitle") }}
          </v-card-title>
          <!-- style="word-break: break-word" necessary for smaller screens! -->
          <v-card-text>{{ $t("roomservice.scheduleText") }}</v-card-text>
        </v-col>
        <v-col align-self="center" cols="3" sm="2" md="1">
          <v-switch
            color="primary"
            :input-value="$store.state.roomservice.showSchedule"
            @change="
              $store.commit('roomservice/TOGGLE_SCHEDULE'),
                $ga.event({
                  eventCategory: 'Toggle room cleaning schedule',
                  eventAction: 'toggle',
                  eventLabel: `Room schedule: ${$store.state.roomservice.showSchedule}`,
                  eventValue: 1,
                })
            "
          ></v-switch>
        </v-col>
      </v-row>
    </v-card>
    <v-container
      justify="space-around"
      class="mb-5"
      v-if="$store.state.roomservice.showSchedule"
    >
      <v-row no-gutters data-v-step="roomctrl_6">
        <v-col
          class="d-flex justify-center"
          v-for="(item, index) in buttons"
          :key="index"
        >
          <toggle-button
            :weekday="item.weekday"
            :state.sync="item.state"
          ></toggle-button>
        </v-col>
      </v-row>
    </v-container>
    <scheduler
      :cardTitle="$t('roomservice.makeupRoom')"
      data-v-step="roomctrl_7"
    ></scheduler>
    <scheduler :cardTitle="$t('roomservice.collectLaundry')"></scheduler>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Scheduler from "@/components/Scheduler";
import ToggleButton from "@/components/ToggleButton";

export default {
  name: "RemoteControl",
  data() {
    return {
      timeout: 8000,
      loadingDnd: false,
      bedroomTemp: 20,
      bedroomFanspeed: 0,
      bedroomLight: 0,
      livingroomTemp: 20,
      livingroomFanspeed: 0,
      livingroomLight: 0,
      dnd: false,
      activatedSnackbar: false,
      deactivatedSnackbar: false,
      loading: false,
      buttons: [
        {
          weekday: "monday",
          state: false,
        },
        {
          weekday: "tuesday",
          state: false,
        },
        {
          weekday: "wednesday",
          state: false,
        },
        {
          weekday: "thursday",
          state: false,
        },
        {
          weekday: "friday",
          state: false,
        },
        {
          weekday: "saturday",
          state: false,
        },
        {
          weekday: "sunday",
          state: false,
        },
      ],
    };
  },
  components: {
    Scheduler,
    ToggleButton,
  },

  computed: {
    isFahrenheit() {
      return (
        this.$config.weatherUnit === "FC" || this.$config.weatherUnit === "F"
      );
    },
    selectedDays() {
      return this.buttons.filter(({ state }) => state === true);
    },
  },
  methods: {
    setDnd() {
      this.loadingDnd = true;
      setTimeout(() => {
        this.loadingDnd = false;
        if (this.dnd === false) {
          this.dnd = true;
          this.activatedSnackbar = true;
          this.deactivatedSnackbar = false;
        } else {
          this.dnd = false;
          this.deactivatedSnackbar = true;
          this.activatedSnackbar = false;
        }
      }, 500);
    },
    bedroomFanCtrl() {
      if (this.bedroomFanspeed > 0) {
        this.bedroomFanspeed = 0;
      }
    },
    bedroomLightCtrl() {
      if (this.bedroomLight > 0) {
        this.bedroomLight = 0;
      }
    },
    livingroomFanCtrl() {
      if (this.livingroomFanspeed > 0) {
        this.livingroomFanspeed = 0;
      }
    },
    livingroomLightCtrl() {
      if (this.livingroomLight > 0) {
        this.livingroomLight = 0;
      }
    },
    ...mapActions("roomservice", ["postConfirmationmail"]),
    cancelCleanup() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.postConfirmationmail({
          sender: "ONEapp",
          subject: "Cancel Cleanup Confirmation",
          content: this.$t("roomservice.cancelMailContent"),
        });
      }, 300);
    },
  },
};
</script>

<style>
.content {
  height: 0px;
}
</style>
